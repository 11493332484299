.crop-editing-cont {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.poi-overlays-cont.video-overlay-cont {
    pointer-events: initial;
}

.poi-graph-cont {
    position: relative;
    height: 120px;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    overflow: hidden;
}

.poi-graph-line {
    width: 100%;
    height: 120px;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
}

.poi-graph-dot {
    position: absolute;
    width: 1px;
    height: 1px;
    margin-top: -1px;
    background-color: white;
}

.poi-graph-dot.yellow {
    width: 2px;
    height: 2px;
    background-color: var(--dark-yellow);
}

.poi-graph-dot.green {
    width: 2px;
    height: 2px;
    background-color: var(--button-green);
}

.graph-type-btns {
    padding-left: 5px;
    border: 1px solid red;
}

.graph-type-btn {
    display: flex;
    align-items: center;
    gap: 3px;
    margin-bottom: 5px;
}
.graph-type-btn input[type="checkbox"] {
    position: unset;
}

.poi-graph-indicator {
    position: absolute;
    top: 0;
    height: 100%;
    width: 1px;
    background-color: white;
}

.loading-graph-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-weight: 600;
    color: var(--light-blue);
}

.keyboard-shortcuts-cont {
    margin: 20px 0;
    border: 1px solid orange;
}