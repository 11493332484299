.video-editing-controls-btn {
    display: flex;
    flex: 1 1;
    justify-content: center;
}
.video-editing-controls-btn > button {
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
    flex: 1;
    max-width: 200px;
}
.video-editing-controls-btn > button:first-of-type {
    margin-left: 0;
}
.video-editing-controls-btn > button:last-of-type {
    margin-right: 0;
}
.editing-container {
    height: 100%;
    width: 100%;
}
.editing-timeline {
    z-index: 1;
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 16px;
    margin-bottom: 5px;
    background: #434A5A;
}
.editing-timeline-interval {
    z-index: 1;
    position: absolute;
    left: 0;
    width: 0;
    height: 100%;
    background: var(--light-blue);
}

.editing-start {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -2px;
    margin: auto;
    height: calc(100% + 8px);
    width: 4px;
    background: var(--light-blue);
    z-index: 2;
}

.editing-end {
    position: absolute;
    top: 0;
    bottom: 0;
    right: -2px;
    margin: auto;
    height: calc(100% + 8px);
    width: 4px;
    background: var(--light-blue);
    z-index: 2;
}

.editing-start.active,
.editing-end.active {
    height: calc(100% + 10px);
    width: 6px;
}

/* .editing-timeline-interval:before,
.editing-timeline-interval:after {
    content: "";
    position: absolute;
    top: -4px;
    height: calc(100% + 8px);
    width: 4px;
    background: var(--light-blue);
    z-index: 2;
}
.editing-timeline-interval:before {
    left: -2px;
}
.editing-timeline-interval:after {
    right: -2px;
} */
.editing-interval-controls {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
}
.editing-interval-controls.zoom {
    justify-content: space-between;
}
.editing-interval-controls .icon-button {
    position: relative;
    font-size: calc(11px + 1vw);
}

.editing-interval-and-button {
    display: flex;
    align-items: center;
    gap: 7px;
}

.editing-interval-time {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
    font-size: 12px;
    color: white;
}

.editing-interval-time div {
    color: rgba(255,255,255, 0.5);
    font-size: 11px;
}

.export-timeline-reset {
    display: flex;
    align-items: center;
    gap: 3px;
    margin: 0 auto;
    margin-top: 2px;
    margin-bottom: 8px;
    width: fit-content;
    font-weight: 500;
    font-size: 12px;
    color: var(--grey);
    cursor: pointer;
    visibility: hidden;
}

.export-timeline-reset.show {
    visibility: visible;
}

.export-timeline-reset svg {
    font-size: 14px;
}

/* NEW SUBCLIP FORM */
form.new-subclip-form {
    display: flex;
    flex-direction: column;
    gap: 25px;
    background: #101525;
    box-shadow: 0 0 12px black;
    padding: 15px;
    margin-top: 25px;
}

form.new-subclip-form > *:not(:last-child) {
    margin-right: 5px;
}

.confirm-cancel-btn-cont.margin-top {
    margin-top: 25px;
}

.confirm-cancel-btn-cont.margin-bottom {
    margin-bottom: 25px;
    margin-top: 15px;
}


.direct-download-cont {
    position: relative;
    min-width: 125px;
    cursor: pointer;
}

.direct-download-option {
    height: 100%;
    display: flex;
    justify-content: center;
    gap: 5px;
    padding: 0.375rem 1.25rem;
    border: 1px solid rgba(255,255,255, 0.25);
    border-radius: 2px;
}

.direct-download-option svg {
    font-size: 15px;
}

.download-types-cont {
    position: absolute;
    top: 35px;
    width: 100%;
    background-color: black;
}

button.download-type-single {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 10px;
    cursor: pointer;
    border: none;
}

.download-tips {
    display: flex;
    margin-top: 15px;
    color: var(--grey);
    font-style: italic;
    font-size: 11px;
}

.download-tips-icon svg {
    font-size: 13px;
    margin-right: 5px;
    margin-left: 1px;
}

/* PREPARE EXPORT */
.download-export-btn {
    display: flex;
    align-items: center;
    gap: 10px;
}

.prepare-export-cont {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 0;
}

.prepare-export-and-advanced {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.prepare-export-title {
    font-size: 17px;
    margin-bottom: 20px;
}

.prepare-export-form {
    width: 400px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: var(--dark-blue);
}

.social-media-logo {
    height: 20px;
    margin-right: 5px;
}

button.prepare-export-btn {
    width: 100%;
    margin-top: 20px;
}

.dropdown-item-single {
    height: 35px;
    cursor: pointer;
}

.selected-preset {
    display: flex;
    align-items: center;
    padding: 3px 0;
}

.advanced-prepare-export {
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 400px;
    gap: 20px;
    opacity: 0;
    transition: transform .25s linear, opacity .2s linear;
    z-index: -1;
}

.export-wide .advanced-prepare-export {
    transform: translateX(calc(100% + 50px));
    opacity: 1;
    z-index: 1;
}

.advanced-export-btn {
    display: flex;
    align-items: center;
    gap: 3px;
    width: fit-content;
    cursor: pointer;
}

.advanced-setting-icon {
    font-size: 20px;
    margin-right: 2px;
}

.advanced-close-icon {
    font-size: 20px;
}

.checkbox-option-cont {
    display: flex;
    gap: 20px;
    margin-top: 2px;
}

.checkbox-option-single {
    display: flex;
    gap: 4px;
    font-size: 14px;
    cursor: pointer;
}

.checkbox-option-single svg {
    font-size: 15px;
}

.timeline-line-cont {
    position: relative;
    width: calc(100% - 1px);
    display: flex;
    height: 32px;
    margin-top: -10px;
}

.time-line {
    position: absolute;
    bottom: 0;
    height: 6px;
    border-left: 1px solid grey;
}

.time-line.tall {
    height: 10px;
}

.time-line.hidden {
    visibility: hidden;
}

.time-line-number {
    position: absolute;
    bottom: 12px;
    transform: translateX(-50%);
    font-size: 10px;
    color: rgba(255, 255, 255, 0.5);
}

.upside-down .time-line {
    bottom: 12px;
}

.upside-down .time-line-number {
    bottom: 0px;
}

.progress-pin {
    position: absolute;
    bottom: -16px;
    height: 38px;
    width: 1px;
    border-left: 1px solid var(--dark-yellow);
    z-index: 10;
    pointer-events: none;
}

.progress-pin.disabled {
    border-color: rgba(150, 150, 150, 0.7);
}

.progress-pin-arrow {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0;
    transform: rotate(180deg) translateX(calc(50% + .5px)) translateY(calc(100% - 1px));
    color: var(--dark-yellow);
    font-size: 10px;
}

.progress-pin.blue {
    border-left: 1px solid var(--light-blue);
}

.progress-pin.blue .progress-pin-arrow {
    color: var(--light-blue);
}

.progress-pin.disabled .progress-pin-arrow {
    color: rgba(150, 150, 150, 0.7);
}

.original-start-and-end {
    position: relative;
    height: 15px;
    width: 100%;
    margin-top: 7px;
}

.original-start-indicator,
.original-end-indicator {
    position: absolute;
    top: -3px;
    transform: translateX(-50%);
    cursor: pointer;
    z-index: 1;
}

.original-start-indicator svg,
.original-end-indicator svg {
    color: var(--dark-yellow);
    font-size: 18px;
}

[data-tooltip]::after {
    content: attr(data-tooltip);
    opacity: 0;
    background-color: rgb(200, 200, 200);
    color: black;
    border-radius: 2px;
    padding: 3px 5px;
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    font-size: 11px;
    pointer-events: none;
}

.original-start-indicator[data-tooltip]::after {
    transform: unset;
}

.original-end-indicator[data-tooltip]::after {
    left: unset;
    right: 0;
    transform: unset;
}

[data-tooltip]:hover::after {
    opacity: 1;
}

/* HOVER */
.advanced-export-btn:hover,
.checkbox-option-single:hover {
    color: var(--dark-yellow);
}
.editing-interval-and-button .icon-button:hover {
    background-color: none;
    background: none;
    color: white;
}
.download-type-single:hover {
    background-color: var(--light-blue);
    color: var(--dark-blue);
}
.export-timeline-reset:hover {
    color: white;
}

@media screen and (max-width: 768px) {
    .video-editing-controls-btn {
        height: 35px;
    }
    .download-export-btn {
        display: flex;
        flex-direction: column;
        gap: 7px;
    }
    .direct-download-option {
        padding: 8px;
        font-size: 12px;
        padding-top: 9px;
    }
}

@media screen and (max-width: 480px) {
    .video-editing-controls-btn {
        height: unset;
        flex-direction: column;
        gap: 5px;
        margin-top: 10px;
    }
    .video-editing-controls-btn > button {
        margin-left: 0;
        margin-right: 0;
        max-width: unset;
    }
}

/* HOVER */
.direct-download-option:hover {
    background-color: var(--light-blue);
    color: var(--dark-blue);
}